/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState, useMemo } from "react";
import Canvas from "./components/Canvas";
import Visualization from "./components/Visualization";
import { WebGLRenderer, Vector3, ShaderMaterial } from "three";
import { css, Global } from "@emotion/react";
import { getPolylineData, getVideo } from "./api";
import { Activity, PointData, City, RequestVideoResponse } from "../../types";
import { Text, Hud, PerspectiveCamera } from "@react-three/drei";
import * as formatters from "./utils/formatters";

type Props = {
  videoId: string;
  generateVideo: boolean;
};

const canvasContainerStyle = css`
  flex-grow: 1;
  background-color: #333;
  flex-shrink: 1;
  min-width: 0px;
`;

const containerStyle = css`
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const titlePosition = new Vector3(-12.5, -10, 0);
const placePosition = new Vector3(-12.5, -11.5, 0);
const metricsPosition = new Vector3(-12.5, -13, 0);
const trademarkPosition = new Vector3(13, -13, 0);

const VideoRender = ({ videoId, generateVideo }: Props) => {
  const rendererRef = useRef<WebGLRenderer | null>(null);
  const [data, setData] = useState<PointData[] | null>(null);
  const [tiles, setTiles] = useState<PointData[][] | null>(null);
  const [videoData, setVideoData] = useState<RequestVideoResponse | null>(null);
  const [activityData, setActivityData] = useState<Activity | null>(null);
  const [nearestCities, setNearestCities] = useState<City[] | null>(null);
  const [generatingVideo, setGeneratingVideo] = useState(generateVideo);
  const [altitudeAreaFactor, setAltitudeAreaFactor] = useState<number | null>(
    null,
  );
  useEffect(() => {
    getVideo(videoId).then((data) => {
      setVideoData(data);
      getPolylineData(parseInt(data.activityId, 10)).then((data) => {
        setData(data.data);
        setTiles(data.tiles);
        setActivityData(data.activity);
        setNearestCities(data.nearestCities);
        setAltitudeAreaFactor(data.altitudeAreaFactor);
      });
    });
  }, []);

  const planeWidth = 100; // Adjust the width as needed
  const solidBlackHeight = 5; // Height (units) of the solid black area
  const gradientHeight = 6; // Height over which the gradient fades to transparent
  const planeHeight = solidBlackHeight + gradientHeight; // Total plane height
  const t0 = solidBlackHeight / planeHeight; // Fraction where gradient starts
  const planePosition = useMemo((): [number, number, number] => {
    // Since the plane is centered, adjust the y position
    return [
      trademarkPosition.x, // x Position
      trademarkPosition.y + planeHeight / 2 - 0.95, // y Position adjusted slightly for alignment
      trademarkPosition.z - 0.001, // z Position slightly behind the text
    ];
  }, [trademarkPosition, planeHeight]);
  const gradientMaterial = useMemo(() => {
    const material = new ShaderMaterial({
      uniforms: {
        t0: { value: t0 },
      },
      vertexShader: `
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
            }
          `,
      fragmentShader: `
            uniform float t0;
            varying vec2 vUv;
            void main() {
              float alpha;
              if (vUv.y <= t0) {
                alpha = 1.0;
              } else {
                alpha = 1.0 - (vUv.y - t0) / (1.0 - t0);
              }
              alpha = clamp(alpha, 0.0, 1.0);
              gl_FragColor = vec4(0.0, 0.0, 0.0, alpha);
            }
          `,
      transparent: true,
    });
    return material;
  }, [t0]);

  return (
    <div css={containerStyle}>
      <Global
        styles={css`
          #chat-widget-container {
            display: none;
          }
        `}
      />
      <div css={canvasContainerStyle}>
        <Canvas>
          {data && tiles && videoData && altitudeAreaFactor && (
            <>
              <Hud>
                <Visualization
                  tiles={tiles}
                  altitudeAreaFactor={altitudeAreaFactor}
                  data={data}
                  rendererRef={rendererRef}
                  autoDownload={false}
                  generatingVideo={generatingVideo}
                  onVideoFinish={() => setGeneratingVideo(false)}
                  nearestCities={nearestCities || undefined}
                  drawLabels={videoData?.features?.drawLabels}
                />
              </Hud>
              <Hud renderPriority={2}>
                <group>
                  <PerspectiveCamera makeDefault position={[0, 0, 30]} />
                  <Text
                    font="/Inter-Regular.woff"
                    fontSize={1.3}
                    color="#9e9e9e"
                    position={titlePosition}
                    anchorX="left"
                    anchorY="top-baseline"
                    maxWidth={25}
                  >
                    {activityData?.name}
                  </Text>
                  <Text
                    font="/Inter-Regular.woff"
                    fontSize={0.9}
                    color="#757575"
                    position={placePosition}
                    anchorX="left"
                    anchorY="top-baseline"
                  >
                    {activityData?.nearestCity}
                  </Text>
                  <Text
                    font="/Inter-Regular.woff"
                    fontSize={0.9}
                    color="#757575"
                    position={metricsPosition}
                    anchorX="left"
                    anchorY="top-baseline"
                  >
                    {formatters.formatDistance(activityData?.distance!)} •{" "}
                    {formatters.formatMovingTime(activityData?.movingTime!)}
                  </Text>
                  <mesh position={planePosition}>
                    <planeGeometry args={[planeWidth, planeHeight]} />
                    <primitive object={gradientMaterial} attach="material" />
                  </mesh>
                  <Text
                    font="/Inter-Regular.woff"
                    fontSize={0.8}
                    color="#9e9e9e"
                    position={trademarkPosition}
                    anchorX="right"
                    anchorY="bottom-baseline"
                  >
                    Powered by PaintMove.com
                  </Text>
                </group>
              </Hud>
            </>
          )}
        </Canvas>
      </div>
    </div>
  );
};

export default VideoRender;
