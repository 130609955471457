/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { WebGLRenderer } from "three";
import { useEffect, useRef, useState } from "react";

import {
  getPolylineData,
  requestVideo,
  getVideos,
  trackEvent,
  updateEmail,
} from "../api";
import Loader from "../Loader";
import Canvas from "./Canvas";
import Visualization from "./Visualization";
import Modal from "./Modal";
import { useToastContext } from "./Toasts";
import LeftIcon from "../LeftIcon";
import RightIcon from "../RightIcon";
import CheckIcon from "./CheckIcon";

import ShareBox from "./ShareBox";
import {
  PointData,
  RequestVideoResponse,
  Activity as ActivityType,
  City,
} from "../../../types";
import { commonButtonStyle, resetButtonStyle } from "../commonStyles";

import { themes } from "../themes";
import EmailNotificationForm from "./EmailNotificationForm";

const canvasContainerStyle = css`
  flex-grow: 1;
  background-color: #333;
  flex-shrink: 1;
  min-width: 0px;
  position: relative;
`;

const labelsButton = (active: boolean) => css`
  ${resetButtonStyle};
  color: #fff;
  background-color: ${active
    ? "rgba(113, 88, 211, 0.5)"
    : "rgba(113, 88, 211, 0.3)"};
  border: 1px solid
    ${active ? "rgba(113, 88, 211, 0.5)" : "rgba(255, 255, 255, 0.3)"};
  border-radius: 12px;
  padding: 3px 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 2em;

  svg {
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const nextPrevButtons = css`
  ${resetButtonStyle};
  &:hover {
    cursor: pointer;
  }
  background-color: rgba(255, 255, 255, 1);
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 3px;
  border-radius: 50%;
`;

const uiButton = (active: boolean, color: string) => css`
  ${resetButtonStyle};
  color: #939393;
  display: block;
  width: 20px;
  height: 20px;
  background-color: #${color};
  border-radius: 50%;
  box-shadow: ${active ? "0 0 0 4px #fff" : "0 0 0 2px #fff"};
  margin: ${active ? "0 8px" : "0 4px"};
  transform: scale(${active ? 1.2 : 1});
  transition:
    transform 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out,
    margin 0.1s ease-in-out;

  &:first-of-type {
    margin-left: none;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    display: block;
  }
`;

const uiButtonsContainer = css`
  display: flex;
  border-radius: 8px;
`;

const buttonsContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ensureProtocol = (url: string) => {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }
  return "https://" + url;
};

const downloadButtonStyle = (loading: boolean) => css`
  ${commonButtonStyle(loading)};
  color: #9485d2;
  background-color: transparent;
  border: 1px solid #9485d2;
`;

const sharebuttonStyle = (loading: boolean) => css`
  @keyframes progress-anim {
    0% {
      width: 0%;
    }
    5% {
      width: 5%;
    }
    10% {
      width: 15%;
    }
    30% {
      width: 40%;
    }
    50% {
      width: 55%;
    }
    80% {
      width: 80%;
    }
    95% {
      width: 90%;
    }
    100% {
      width: 100%;
    }
  }

  ${commonButtonStyle(loading, true)};

  margin-left: 3px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 740px) {
    font-size: 12px;
  }

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0%;
    background-color: #9485cc;

    animation: ${loading ? "progress-anim 200s ease 0s forwards" : "none"};
  }
`;

const label = css`
  position: relative;
  z-index: 2;
`;

const nextPrevButtonsContainer = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

// const stravaLink = css`
//   color: #939393;
//   display: inline-block;
//   margin-right: 5px;
//   font-size: 14px;
// `;

const optionsContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const bottomBar = css`
  position: fixed;
  bottom: 20px;
  left: 10px;
  right: 10px;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  transform: translateX(120px);
  backdrop-filter: blur(10px);
  font-size: 13px;
  border-radius: 20px;
  max-width: 330px;

  @media (max-width: 860px) {
    max-width: 330px;
  }

  @media (max-width: 740px) {
    margin: 0px 50px;
    max-width: none;
    transform: none;
  }

  @media (max-width: 400px) {
    margin: 0px;
    max-width: none;
    transform: none;
    z-index: 2147483640;
  }
`;

type Props = {
  activityId: number;
  userData: {
    email: string;
  };
  handleNextClick: () => void;
  handlePreviousClick: () => void;
  addVideoToChecker: (
    callback: () => void,
  ) => Promise<RequestVideoResponse | false>;
};

const Activity = ({
  activityId,
  addVideoToChecker,
  handleNextClick,
  handlePreviousClick,
  userData,
}: Props) => {
  const rendererRef = useRef<WebGLRenderer | null>(null);
  const [generatingVideo, setGeneratingVideo] = useState<boolean>(false);
  const [pendingVideo, setPendingVideo] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);
  const addToast = useToastContext();
  const [drawSurface, setDrawSurface] = useState<boolean>(true);
  const [drawLines, setDrawLines] = useState<boolean>(true);
  const [drawLabels, setDrawLabels] = useState<boolean>(true);
  const [theme] = useState<(typeof themes)[0]>(themes[0]);
  const [showEmailDialog, setShowEmailDialog] = useState<boolean>(false);

  const [activityData, setActivityData] = useState<{
    data: PointData[];
    tiles: PointData[][];
    activity: ActivityType;
    nearestCities: City[];
    altitudeAreaFactor: number;
  } | null>(null);
  const [video, setVideo] = useState<RequestVideoResponse | null>(null);

  const handleDownloadClick = () => {
    requestVideo(activityId!, { drawLabels: drawLabels });
    addVideoToChecker(() => {
      setShowDialog(true);
    }).then((response) => {
      if (response) {
        setVideo(response);
        setPendingVideo(false);
      }
    });
    setShowEmailDialog(true);
    setPendingVideo(true);
  };

  const handleShareClick = () => {
    setShowDialog(true);
    trackEvent("Share requested", {
      activityId: activityId.toString(),
    });
  };

  useEffect(() => {
    setActivityData(null);
    getPolylineData(activityId).then((data) => {
      setActivityData(data);
    });
    checkVideoStatus();
  }, [activityId]);

  const checkVideoStatus = () => {
    getVideos(activityId).then((data) => {
      setVideo(data.videos[0]);
      if (
        data.videos &&
        data.videos[0] &&
        (!data.videos[0].status || data.videos[0].status === "PENDING")
      ) {
        setPendingVideo(true);
      } else {
        setPendingVideo(false);
      }
    });
  };

  const handleSendEmailClick = (email: string) => {
    if (email) {
      updateEmail(email);
      setShowEmailDialog(false);
      addToast({
        message: "We will notify you when your animation is ready.",
        type: "info",
        id: String(Math.random()),
      });
    }
  };

  return (
    <div css={canvasContainerStyle}>
      {!activityData && <Loader />}
      <div css={bottomBar}>
        {/* <div css={uiButtonsContainer}>

          {themes.map((_theme) => (
            <button css={uiButton(_theme === theme, _theme.lightColor)} onClick={() => setTheme(_theme)}>

            </button>
          ))}
        </div> */}
        <div css={nextPrevButtonsContainer}>
          <button css={nextPrevButtons} onClick={handlePreviousClick}>
            <LeftIcon />
          </button>
          <button css={nextPrevButtons} onClick={handleNextClick}>
            <RightIcon />
          </button>
        </div>
        <div css={optionsContainer}>
          <button
            css={labelsButton(drawLabels)}
            onClick={() => setDrawLabels(!drawLabels)}
          >
            {drawLabels ? <CheckIcon /> : null}
            Labels
          </button>
        </div>
        <Modal
          show={showEmailDialog}
          close={() => setShowEmailDialog(false)}
          title="Animation is preparing..."
        >
          <EmailNotificationForm
            onEmailClick={handleSendEmailClick}
            email={userData?.email}
            onCloseClick={() => setShowEmailDialog(false)}
          />
        </Modal>
        <div css={buttonsContainer}>
          {/* <a
            css={stravaLink}
            target="_blank"
            rel="noreferrer noopener"
            href={`https://strava.com/activities/${activityData?.activity.id}`}
          >
            View on Strava
          </a> */}
          {(pendingVideo || !video) && (
            <button
              onClick={handleDownloadClick}
              css={sharebuttonStyle(pendingVideo)}
              disabled={pendingVideo}
            >
              <span className="progress" />
              <span css={label}>
                {pendingVideo ? "Creating..." : "Create video"}
              </span>
            </button>
          )}
          {video &&
            activityData &&
            (!video.status || video.status === "DONE") &&
            video.link && (
              <>
                <button
                  onClick={handleShareClick}
                  css={commonButtonStyle(false, true)}
                >
                  Share
                </button>
                <Modal
                  show={showDialog}
                  close={() => setShowDialog(false)}
                  title={activityData!.activity.name}
                >
                  <ShareBox
                    activityId={activityId}
                    title={activityData!.activity.name}
                    videoUrl={ensureProtocol(video.link)}
                  />
                </Modal>
              </>
            )}
          {video && (!video.status || video.status === "ERROR") && (
            <a css={downloadButtonStyle(generatingVideo)}>Error occured</a>
          )}
        </div>
      </div>
      <Canvas>
        {activityData && (
          <Visualization
            drawSurface={drawSurface}
            drawLines={drawLines}
            drawLabels={drawLabels}
            tiles={activityData.tiles}
            data={activityData.data}
            nearestCities={activityData.nearestCities}
            altitudeAreaFactor={activityData.altitudeAreaFactor}
            rendererRef={rendererRef}
            generatingVideo={generatingVideo}
            theme={theme}
            onVideoFinish={() => setGeneratingVideo(false)}
          />
        )}
      </Canvas>
    </div>
  );
};

export default Activity;
