import {
  BufferGeometry,
  Float32BufferAttribute,
  Vector3,
  Shape,
  Curve,
} from "three";

type Frames = {
  tangents: Vector3[];
  normals: Vector3[];
  binormals: Vector3[];
};

function CustomExtrudeGeometry(
  shape: Shape,
  pathCurve: Curve<Vector3>,
  frames: Frames,
  steps: number,
) {
  const geometry = new BufferGeometry();

  const vertices = [] as number[];
  const indices = [] as number[];

  const shapePoints = shape.getPoints();

  for (let i = 0; i <= steps; i++) {
    const u = i / steps;

    const normal = frames.normals[i];
    const binormal = frames.binormals[i];

    const position = pathCurve.getPointAt(u);

    // For each point in the shape, compute its position in 3D space
    shapePoints.forEach((shapePoint) => {
      const x = shapePoint.x;
      const y = shapePoint.y;

      // Position the shape point along the path with the orientation given by normal and binormal
      const vertex = new Vector3();
      vertex.copy(position);
      vertex.add(
        normal
          .clone()
          .multiplyScalar(x)
          .add(binormal.clone().multiplyScalar(y)),
      );

      vertices.push(vertex.x, vertex.y, vertex.z);
    });

    // Generate indices for faces (triangles)
    if (i < steps) {
      const offset = i * shapePoints.length;
      for (let j = 0; j < shapePoints.length - 1; j++) {
        const a = offset + j;
        const b = offset + j + 1;
        const c = offset + j + shapePoints.length;
        const d = offset + j + 1 + shapePoints.length;

        // Two triangles per segment
        indices.push(a, b, c);
        indices.push(b, d, c);
      }
    }
  }

  geometry.setAttribute("position", new Float32BufferAttribute(vertices, 3));
  geometry.setIndex(indices);
  geometry.computeVertexNormals();

  return geometry;
}

export default CustomExtrudeGeometry;
