import {
    Vector3,
} from "three";
import { Line } from "@react-three/drei";
import { Theme } from "../../../themes";

type Props = {
    tilesMappedToPoints: Vector3[][],
    tilesMappedToPoints2: Vector3[][],
    gridFilterFactor: number,
    theme: Theme,
    gridColor: string,
    gridOpacity: number
}

const Grid = ({ tilesMappedToPoints, tilesMappedToPoints2, gridFilterFactor, theme, gridColor, gridOpacity }: Props) => {
    return (
        <>
            {tilesMappedToPoints
                .filter((_, index) => index % gridFilterFactor === 0)
                .map((points, index) => (
                    <Line
                        points={points}
                        color={gridColor}
                        lineWidth={.5}
                        key={index}
                        depthWrite={false}
                        renderOrder={100}
                        opacity={gridOpacity}
                        transparent
                    />
                ))}

            {tilesMappedToPoints2
                .filter((_, index) => index % gridFilterFactor === 0)
                .map((points, index) => (
                    <Line
                        points={points}
                        color={gridColor}
                        lineWidth={.5}
                        key={index}
                        depthWrite={false}
                        renderOrder={100}
                        opacity={gridOpacity}
                        transparent
                    />
                ))}
        </>
    )
}

export default Grid
