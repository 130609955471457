import { Canvas as CanvasFiber } from "@react-three/fiber";

const Canvas = ({ children }: { children: React.ReactNode }) => {
  return (
    <CanvasFiber
      camera={{ position: [100, 300, 0], fov: 500, near: 10, far: 20000 }}
      shadows
      dpr={[1, 2]}
      gl={{ preserveDrawingBuffer: true }}
      frameloop="demand"
    >
      {children}
    </CanvasFiber>
  );
};

export default Canvas;
