import App from "./App";
import VideoRender from "./VideoRender";
import { ToastContextProvider } from "./components/Toasts";

const urlParams = new URLSearchParams(window.location.search);
const videoIdParam = urlParams.get("video_id");
const prerenderVideoId = urlParams.get("prerender_video_id");

const Router = () => {
  return (
    <ToastContextProvider>
      {prerenderVideoId && (
        <div
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            margin: "0 auto",
            aspectRatio: "1 / 1",
          }}
        >
          <VideoRender
            videoId={prerenderVideoId as string}
            generateVideo={false}
          />
        </div>
      )}
      {videoIdParam && (
        <VideoRender videoId={videoIdParam as string} generateVideo />
      )}
      {!videoIdParam && !prerenderVideoId && <App />}
    </ToastContextProvider>
  );
};

export default Router;
