import { useState } from "react";
import { Text, Billboard } from "@react-three/drei";
import {
  Vector3,
  BufferGeometry,
  Line as ThreeLine,
  LineBasicMaterial,
} from "three";
import { Theme } from "../../../themes";
import { City } from "types";

type Props = {
  citiesMappedToPoints: Vector3[];
  nearestCities: City[];
  theme: Theme;
  zoomFactor: number;
};
const Cities = ({
  citiesMappedToPoints,
  nearestCities,
  theme,
  zoomFactor,
}: Props) => {
  const [textDimensions, setTextDimensions] = useState<
    { width: number; height: number }[]
  >([]);
  return (
    <>
      {citiesMappedToPoints.map((point, index) => (
        <group key={index} position={point.toArray()}>
          <mesh renderOrder={1000000}>
            <cylinderGeometry
              args={[
                zoomFactor /
                  (nearestCities[index].featureCode !== "PPLX" ? 300 : 600),
                zoomFactor /
                  (nearestCities[index].featureCode !== "PPLX" ? 300 : 600),
                zoomFactor / 8000,
                20,
              ]}
            />
            <meshStandardMaterial
              attach="material"
              color={`#fff`}
              opacity={0.7}
              depthWrite={false}
              transparent
            />
          </mesh>

          {/* Draw the Line */}
          {textDimensions[index] && (
            <primitive
              object={
                new ThreeLine(
                  new BufferGeometry().setFromPoints([
                    new Vector3(0, zoomFactor / 8000, 0),
                    new Vector3(
                      0,
                      textDimensions[index].height / 2 +
                        Math.abs(zoomFactor / 50),
                      0,
                    ),
                  ]),
                  new LineBasicMaterial({
                    color: 0xffffff,
                    opacity: 0.7,
                    transparent: true,
                  }),
                )
              }
            />
          )}

          {/* Group for Text and Background */}

          <Billboard follow>
            <Text
              lineHeight={1.5}
              fontSize={
                zoomFactor /
                (nearestCities[index].featureCode !== "PPLX" ? 150 : 250)
              }
              color={`#${theme.text}`}
              anchorY="bottom"
              anchorX="center"
              fillOpacity={
                nearestCities[index].featureCode !== "PPLX" ? 1 : 0.7
              }
              renderOrder={9999}
              position={[0, zoomFactor / 50, 1]}
              onSync={(self) => {
                const currentDimensions = self.geometry.boundingBox;
                const width = currentDimensions.max.x - currentDimensions.min.x;
                const height =
                  currentDimensions.max.y - currentDimensions.min.y;
                setTextDimensions((prev) => ({
                  ...prev,
                  [index]: { width, height },
                }));
              }}
              textAlign="center"
              maxWidth={
                zoomFactor /
                (nearestCities[index].featureCode !== "PPLX" ? 10 : 18)
              }
              font="/Inter-Regular.woff"
            >
              {nearestCities[index].name}
            </Text>
          </Billboard>
        </group>
      ))}
    </>
  );
};

export default Cities;
