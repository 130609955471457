import { useCallback, useEffect, useRef, useState } from "react";
import { Vector3, BufferAttribute, Mesh, Color, Texture } from "three";
import { angleToRad } from "../../../utils/math";
import { Theme } from "../../../themes";

type Props = {
  tilesMappedToPoints: Vector3[][];
  tilesMappedToPoints2: Vector3[][];
  position: Vector3;
  theme: Theme;
  drawLabels: boolean;
  texture: Texture;
  color: string; // Add color prop
};

const Surface = ({
  drawLabels,
  tilesMappedToPoints,
  tilesMappedToPoints2,
  position,
  theme,
  texture,
  color, // Destructure color from props
}: Props) => {
  const surfaceRef = useRef<Mesh>(null!);
  const [forceRender, setForceRender] = useState(0);
  const rectangleHeight =
    tilesMappedToPoints[0][tilesMappedToPoints[0].length - 1].z -
    tilesMappedToPoints[0][0].z;
  const rectangleWidth =
    tilesMappedToPoints2[0][tilesMappedToPoints2[0].length - 1].x -
    tilesMappedToPoints2[0][0].x;
  const darkColor = new Color(`#${theme.darkColor}`);
  const lightColor = new Color(`#${theme.lightColor}`);

  const updateGeometry = useCallback(() => {
    if (!surfaceRef.current || !surfaceRef.current.geometry) {
      return;
    }

    const { geometry } = surfaceRef.current;
    const { position } = geometry.attributes;

    if (!geometry.attributes.color) {
      const numVertices = position.count;
      const colors = new Float32Array(numVertices * 3).fill(1.0);
      geometry.setAttribute("color", new BufferAttribute(colors, 3));
    }

    const { color } = geometry.attributes;

    let minHeight = Infinity,
      maxHeight = -Infinity;

    tilesMappedToPoints.forEach((row) => {
      row.forEach((point) => {
        minHeight = Math.min(minHeight, point.y);
        maxHeight = Math.max(maxHeight, point.y);
      });
    });

    tilesMappedToPoints.forEach((row, rowIndex) => {
      row.reverse().forEach((point, pointIndex) => {
        const index = rowIndex * row.length + pointIndex;
        position.array[index * 3 + 2] = point.y;

        const normalizedY = (point.y - minHeight) / (maxHeight - minHeight);

        let colorValue = darkColor.clone();
        colorValue.lerp(lightColor, normalizedY);

        color.array[index * 3] = colorValue.r;
        color.array[index * 3 + 1] = colorValue.g;
        color.array[index * 3 + 2] = colorValue.b;
      });
    });
    surfaceRef.current.position.y = 0;
    position.needsUpdate = true;
    color.needsUpdate = true;

    geometry.computeVertexNormals();
    setForceRender(forceRender + 1);
  }, [
    surfaceRef.current,
    tilesMappedToPoints,
    tilesMappedToPoints2,
    drawLabels,
  ]);

  useEffect(() => {
    updateGeometry();
  }, [
    tilesMappedToPoints,
    tilesMappedToPoints2,
    updateGeometry,
    darkColor,
    lightColor,
  ]);

  return (
    <mesh
      ref={surfaceRef}
      position={position}
      rotation={[angleToRad(-90), 0, angleToRad(90)]}
      renderOrder={10}
    >
      <meshMatcapMaterial
        attach="material"
        depthWrite={false}
        matcap={texture}
        vertexColors
        onBeforeCompile={(shader) => {
          shader.fragmentShader = shader.fragmentShader.replace(
            `gl_FragColor = matcapColor;`,
            `
              #ifdef USE_COLOR
                matcapColor.rgb *= vColor.rgb;
              #endif
              gl_FragColor = matcapColor;
            `,
          );
        }}
      />
      <planeGeometry
        attach="geometry"
        args={[
          rectangleHeight,
          rectangleWidth,
          tilesMappedToPoints[0].length - 1,
          tilesMappedToPoints.length - 1,
        ]}
      />
    </mesh>
  );
};

export default Surface;
